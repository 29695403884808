.no-margin{
  margin: 0;
}

.wp-block-image.en-caption,
.en-caption{
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  figcaption{
    align-self: flex-end;
    margin: 0;
    padding: rem-calc(2) rem-calc(16);
  }
}

.site-contents p:empty{
  display: none;
}

.wp-embedded-content{
  display: none;
}
