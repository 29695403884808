.page-content.contact{
  & .page-content__inner{
    width: 100%;
  }
  & .wp-block-group{
    @extend .wideLimit;
  }
  & .wp-block-group__inner-container{
    display: flex;
    @include breakpoint(medium down){
      flex-direction: column;
    };
  }
  & p{
    flex: 0 0 auto;
    width: calc(#{$column} * 3.65 );
    font-size: rem-calc(13);
    letter-spacing: 0.1em;
    line-height: 2.08;
    @include breakpoint(small down){
      width: 100%;
    };
  }
  .mw_wp_form{
    width: calc(#{$column} * 9);
    padding-left: $column;
    text-align: left;
    @include breakpoint(medium down){
      width: 100%;
      padding-left: 0;
    };
    table{
      width: 100%;
      margin: 0;
    }
    tr{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: auto;
      margin-bottom: rem-calc(12);

      &:last-of-type{
        margin-bottom: rem-calc(32);
      }
    }
    th{
      display: block;
      text-align: left;
      @extend %ja-font;
      min-height: 0%;
      margin-top: rem-calc(-11/2);
      padding-bottom: rem-calc(6);
      font-size: rem-calc(12);
      em{
        @extend .visually-hidden;
      }
    }
    td{
      display: block;
    }
    input{
      width: 52%;
      padding-top: rem-calc(12);
      padding-bottom: rem-calc(6);
      border: none;
      border-bottom: 1px solid $dark-brown;
      font-size: rem-calc(14);
      letter-spacing: 0.075em;
      color: $dark-brown;
      @extend %ja-font;
      @include breakpoint(medium down){
        width: 80%;
      };
      &[name = "お名前"],
      &[name = "ふりがな"]{
        width:37%;
        @include breakpoint(small down){
          width: 55%
        };
      }
    }
    p{
      width: 100%;
    }
    textarea{
      width: 100%;
      margin-top: rem-calc(6);
      padding: 0;
      border: none;
      border-top: 1px solid $white;
      border-bottom: 1px solid $dark-brown;
      font-size: rem-calc(14);
      letter-spacing: 0.075em;
      line-height: 2.5em;
      color: $dark-brown;
      background: linear-gradient($shadow-gray 1px, transparent 1px) $white;
      background-size: auto 2.5em;
      background-origin: border-box;
      background-attachment: local;
    }
  }
} //close .contact

.mw_wp_form_preview{
  letter-spacing: 0.1em;
  line-height: 2.08;
  td{
    font-size: rem-calc(14);
  }
}
.mwform__btn{
  display: flex;
  justify-content: flex-end;
}
.form-confirm-button,
.form-back-button,
.form-submit-button{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: rem-calc(10);
  padding: rem-calc(11) rem-calc(21);
  border: 1px solid #707070;
  border-radius: 3px;
  color: $dark-brown;
  background-color: $white;
  cursor: pointer;
  &::after{
    position: absolute;
    content: ""; z-index: -1;
    width: calc(100% - 12px);
    height: 100%;
    left: 6px;
    bottom: -3px;
    border-radius: 3px;
    background-color: $shadow-gray;
  }
  &:hover{
    transform: translateY(3px);
    z-index: 2;
    &::after{
      content: "";
      width: 0; height: 0;
    }
  }
}
