$ja-font: "SST Japanese W05 Regular";
$ja-font-light: 'SST Japanese W05 Light';

%ja-font{
  font-family: $ja-font, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}
%ja-font-light{
  font-family: $ja-font-light, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

html{
  font-size: 16px;
}

body{
  font-family: $ja-font;
}

p{
  font-size: rem-calc(12);
  margin-top: rem-calc(-11/2);
  margin-bottom: 1em;
}

i{ font-feature-settings: "ital"; }

a{ text-decoration: none; }

h1, h2, h3, h4, h5, h6{
  margin: 0;
  font-weight: normal;
}

h2, h3, h4, h5, h6{
  margin-bottom: rem-calc(20);
}
