.wp-block-columns{
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: -0.5em;
  margin-right: -0.5em;
  @include breakpoint(small down){
    flex-direction: column;
  }
  .wp-block-column{
    flex: 0 1 auto;
    // min-width: $column-size * 2;
    margin-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &:first-of-type{
      padding-left: 0;
    }
    &:last-of-type{
      padding-right: 0;
    }
    @include breakpoint(medium up){
      // max-width: $column-size * 22;
    }
    @include breakpoint(small down){
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.alignwide .wp-block-column{
    // width: $column-size * 12;
    @include breakpoint(small down){
      // width: $column-size * 24;
    }
  }

}
