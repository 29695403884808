.is-show-all-profile,
.is-show-en-text{
  @extend .visually-hidden;
}

.is-show-all-profile+.profile-container{
  display: none;
  &__text{
    margin-right: rem-calc(48);
  }
}
.is-show-all-profile:checked+.profile-container{
  display: flex;
}

.is-show-en-text+.profile__description{
  display: none;
}
.is-show-en-text:checked+.profile__description{
  display: block;
}

label{
  @extend %content-link;
  font-size: rem-calc(12);
}
