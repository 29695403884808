html{
  @include breakpoint(medium only){
    font-size: 80%;
  }
  @media(max-width: 800px) and (min-width: 640px){
    font-size: 1.43vw;
  }
  @include breakpoint(small only){
    font-size: 16px;
  }
  @media(max-width: 320px){
    font-size: 14px;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

img{
  max-width: 100%;
  height: auto;
}

ul{
  list-style: none;
}

input[type="button"],
input[type="email"],
input[type="text"],
input[type="submit"],
textarea {
   -webkit-appearance: none;
   border-radius: 0;
}
