@charset "UTF-8";
.screen-reader-text,
.visually-hidden,
.single-page__header,
.page-content.contact .mw_wp_form th em,
.is-show-all-profile,
.is-show-en-text,
.skip-anchor:not(:focus) {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
  padding: 0;
}

.skip-anchor {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

* {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
}

a, p {
  word-wrap: break-word;
}

main {
  display: block;
}

figure {
  margin: 0;
}

address {
  font-style: normal;
}

img {
  max-width: 100%;
}

.no-bullet {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.align-middle {
  align-items: center;
}

.image-cover, img {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.image-contain, img.contain, .contain img {
  object-fit: contain;
  font-family: "object-fit: contain;";
}

img {
  overflow: hidden;
  vertical-align: bottom;
}
@keyframes blink-once {
  0%, 49%, 100% {
    opacity: 1;
  }
  50%, 99% {
    opacity: 0;
  }
}
.screen-reader-text,
.visually-hidden,
.single-page__header,
.page-content.contact .mw_wp_form th em,
.is-show-all-profile,
.is-show-en-text,
.skip-anchor:not(:focus) {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
  padding: 0;
}

.skip-anchor {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  html {
    font-size: 80%;
  }
}
@media (max-width: 800px) and (min-width: 640px) {
  html {
    font-size: 1.43vw;
  }
}
@media print, screen and (max-width: 39.99875em) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  html {
    font-size: 14px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

input[type=button],
input[type=email],
input[type=text],
input[type=submit],
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

body {
  color: #595959;
}

.page-content.contact .mw_wp_form input, .page-content.contact .mw_wp_form th {
  font-family: "SST Japanese W05 Regular", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

.single-page__header-title {
  font-family: "SST Japanese W05 Light", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-variant-ligatures: no-common-ligatures;
  font-feature-settings: "liga" 0, "clig" 0;
}

html {
  font-size: 16px;
}

body {
  font-family: "SST Japanese W05 Regular";
}

p {
  font-size: 0.75rem;
  margin-top: -0.34375rem;
  margin-bottom: 1em;
}

i {
  font-feature-settings: "ital";
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
}

h2, h3, h4, h5, h6 {
  margin-bottom: 1.25rem;
}

.wideLimit, .pageNav, .single-page, .page-content, .page-content.contact .wp-block-group, .notfound__wrapper h2, .notfound {
  max-width: 69rem;
  margin: auto;
}

.no-margin {
  margin: 0;
}

.wp-block-image.en-caption,
.en-caption {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.wp-block-image.en-caption figcaption,
.en-caption figcaption {
  align-self: flex-end;
  margin: 0;
  padding: 0.125rem 1rem;
}

.site-contents p:empty {
  display: none;
}

.wp-embedded-content {
  display: none;
}

.alignfull, .wp-block-columns.alignfull {
  margin-left: -128.5%;
  width: 309.09%;
}
@media print, screen and (max-width: 63.99875em) {
  .alignfull, .wp-block-columns.alignfull {
    margin-left: calc(26% - 50vw);
    margin-right: calc(74% - 50vw);
    width: 100vw;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .alignfull, .wp-block-columns.alignfull {
    margin-left: calc(70% - 50vw);
    margin-right: calc(30% - 50vw);
  }
}

.alignwide, .wp-block-columns.alignwide {
  margin-right: -34.6153846154%;
  width: 150%;
}
@media print, screen and (max-width: 39.99875em) {
  .alignwide, .wp-block-columns.alignwide {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.wp-block-column h2:first-of-type {
  margin-top: 0;
}
.wp-block-column img {
  margin-top: 0.3333333333rem;
}
.wp-block-column figcaption {
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.023em;
  line-height: 1.5rem;
}

.wp-block-image figcaption {
  margin-top: 1rem;
  font-weight: bold;
}

.wp-block-columns {
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: -0.5em;
  margin-right: -0.5em;
}
@media print, screen and (max-width: 39.99875em) {
  .wp-block-columns {
    flex-direction: column;
  }
}
.wp-block-columns .wp-block-column {
  flex: 0 1 auto;
  margin-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.wp-block-columns .wp-block-column:first-of-type {
  padding-left: 0;
}
.wp-block-columns .wp-block-column:last-of-type {
  padding-right: 0;
}
@media print, screen and (max-width: 39.99875em) {
  .wp-block-columns .wp-block-column {
    padding-left: 0;
    padding-right: 0;
  }
}
.right-arrow,
.left-arrow {
  position: relative;
  display: inline;
}
.right-arrow::before, .right-arrow::after,
.left-arrow::before,
.left-arrow::after {
  content: "";
  position: absolute;
  bottom: 0.25em;
  height: 1px;
  background: #595959;
}
.right-arrow::before,
.left-arrow::before {
  width: 45px;
}
.right-arrow::after,
.left-arrow::after {
  width: 12px;
  overflow: hidden;
}
.right-arrow__link,
.left-arrow__link {
  position: relative;
}
.right-arrow__link::after,
.left-arrow__link::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.right-arrow {
  padding-right: 6.125rem;
}
.right-arrow::before, .right-arrow::after {
  right: 0;
}
.right-arrow::after {
  transform-origin: right center;
  transform: rotate(33deg);
}

.left-arrow {
  padding-left: 6.125rem;
}
.left-arrow::before, .left-arrow::after {
  left: 0;
}
.left-arrow::after {
  transform-origin: left center;
  transform: rotate(-33deg);
}

.x-mark {
  position: relative;
}
.x-mark::before, .x-mark::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 24px;
  height: 1px;
  background: #c1c3c7;
}
.x-mark::before {
  transform: rotate(33deg);
}
.x-mark::after {
  transform: rotate(-33deg);
}

.page-contents,
.page-content {
  padding-top: 3.75rem;
}

.site-header:before {
  content: "";
  transition: background-color 0.025s linear;
  position: fixed;
  z-index: 128;
  background: rgba(255, 255, 255, 0);
}
body.menu-open .site-header:before {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(43, 43, 43, 0.05);
}

.header {
  padding-bottom: 1.25rem;
}
.header__cover {
  display: flex;
  justify-content: space-between;
  margin-top: 1.875rem;
  margin-left: 2.5rem;
}
@media print, screen and (max-width: 63.99875em) {
  .header__cover {
    margin-left: 1.25rem;
  }
}
.header__title a {
  display: flex;
  align-items: flex-end;
  font-size: 0.6875rem;
}
@media print, screen and (max-width: 39.99875em) {
  .header__title a {
    display: block;
  }
}
.header__title h1 {
  margin: 0;
  margin-block-start: 0;
}
@media print, screen and (max-width: 39.99875em) {
  .header__title h1 {
    position: relative;
    height: 0;
    padding-top: 9%;
  }
  .header__title h1 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.header__title p {
  font-size: 0.6875rem;
  margin-top: 0.5rem;
  margin-bottom: -0.171875rem;
}
@media print, screen and (min-width: 40em) {
  .header__title p {
    margin-left: 1.4375rem;
  }
}

.global-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 200;
  pointer-events: none;
}
.menu-open .global-nav {
  display: flex;
  flex-direction: column;
  height: auto;
  pointer-events: all;
}
.global-nav__menu {
  transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateX(50%);
  pointer-events: all;
  background-color: #fff;
}
.menu-open .global-nav__menu {
  transition: all 250ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateX(0%);
}
.global-nav__menu-btn, .global-nav__close-btn {
  position: relative;
  margin: 0;
  padding: 33px 47.5px;
  border: none;
  background-color: #fff;
  cursor: pointer;
}
.global-nav__menu-btn {
  transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
}
.menu-open .global-nav__menu-btn {
  transition: all 250ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateX(-95px);
}
.global-nav__menu-btn__close-btn {
  transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
}
.menu-open .global-nav__menu-btn__close-btn {
  transition: all 250ms cubic-bezier(0.19, 1, 0.22, 1);
}
.global-nav__menu-label, .global-nav__menu-label::before, .global-nav__menu-label::after, .global-nav__close-label, .global-nav__close-label::before {
  content: "";
  position: absolute;
  display: block;
  height: 2px; /*線の太さ*/
  width: 53px; /*長さ*/
  background: #595959;
}
.global-nav__menu-label, .global-nav__close-label {
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.global-nav__menu-label::before {
  bottom: 9px;
}
.global-nav__menu-label::after {
  bottom: -9px;
}
.global-nav__close-label, .global-nav__close-label::before {
  width: 28px;
}
.global-nav__close-label {
  transform: translateX(-50%) rotate(45deg);
}
.global-nav__close-label::before {
  transform: rotate(-90deg);
}
.global-nav__lists {
  transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateX(100%);
  flex-grow: 1;
  overflow-y: scroll;
  width: 100%;
  margin: 0;
  padding: 2.25rem 0 2.25rem 0.75rem;
  background-color: #fff;
  font-size: 0.75rem;
}
.menu-open .global-nav__lists {
  transition: all 250ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateX(0%);
}
.global-nav__list {
  padding-bottom: 2rem;
  position: relative;
}
.global-nav__list a::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.global-nav__title {
  margin-bottom: 0.75em;
}
.global-nav__title-en {
  transition: color 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  max-width: 15em;
  font-size: 0.6875rem;
  color: #777;
}
a:hover .global-nav__title-en {
  color: #c1c3c7;
}

a, label, .about-footer a, .about-content a {
  transition: color 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: #2b2b2b;
  cursor: pointer;
}
a:hover, label:hover {
  color: #777;
}

label, .about-footer a, .about-content a {
  text-decoration: underline;
  cursor: pointer;
}

.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.tns-horizontal.tns-subpixel > .tns-item {
  vertical-align: bottom;
}

.about {
  position: relative;
}

.about-header {
  position: absolute;
  display: inline-block;
  top: -0.34375rem;
  right: 0;
}

.about-content {
  display: flex;
  line-height: 1.75;
}
@media print, screen and (max-width: 39.99875em) {
  .about-content {
    flex-direction: column;
    margin-right: 1.25rem;
  }
}
.about-content__title {
  margin: -0.2291666667rem 0 0.875rem;
  font-size: 0.8125rem;
}
.about-content__office {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
}
@media print, screen and (min-width: 40em) {
  .about-content__office {
    width: 20%;
    min-width: 20rem;
    margin-top: -0.2291666667rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .about-content__office {
    margin-top: 2rem;
  }
}
.about-content__profile {
  display: flex;
  flex-direction: column;
  padding-left: 2.5rem;
}
.about-content__profile .profile-container__title-en {
  margin: -0.34375rem 0 0.875rem;
}
@media print, screen and (max-width: 39.99875em) {
  .about-content__profile {
    margin-top: 3rem;
    padding-left: 0;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .profile-container {
    flex-direction: column;
  }
}
.profile-container__cover {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

.profile {
  padding-right: 3rem;
}
@media print, screen and (max-width: 63.99875em) {
  .profile {
    padding-bottom: 1rem;
    padding-right: 1.25rem;
  }
  .profile:last-child {
    padding-bottom: 0;
  }
}

.about-footer {
  display: flex;
  padding-top: 3.125rem;
}
@media print, screen and (max-width: 39.99875em) {
  .about-footer {
    display: block;
  }
}

.copyright {
  font-size: 0.75rem;
  line-height: 1.75;
}

.site-footer-nav {
  flex-grow: 1;
  align-self: flex-end;
  padding-right: 3.25rem;
  text-align: right;
  font-size: 0.6875rem;
  white-space: nowrap;
}
@media print, screen and (max-width: 39.99875em) {
  .site-footer-nav {
    margin-top: 1rem;
    padding-right: 0;
    text-align: left;
  }
}

.site-footer {
  margin-left: 2.5rem;
  padding-top: 6.8125rem;
  padding-bottom: 2.8125rem;
}
@media print, screen and (max-width: 39.99875em) {
  .site-footer {
    margin-left: 1.25rem;
  }
}
.site-footer h3, .site-footer h4, .site-footer h5, .site-footer h6 {
  margin: 0;
  font-size: 0.75rem;
}
.site-footer p {
  margin: 0;
  line-height: 1.75;
}
.site-footer__contact {
  font-size: 0.875rem;
}
@media print, screen and (max-width: 39.99875em) {
  .site-footer__contact {
    margin-bottom: 1.25rem;
  }
  .site-footer__contact span {
    z-index: -1;
  }
}

.is-show-all-profile + .profile-container {
  display: none;
}
.is-show-all-profile + .profile-container__text {
  margin-right: 3rem;
}

.is-show-all-profile:checked + .profile-container {
  display: flex;
}

.is-show-en-text + .profile__description {
  display: none;
}

.is-show-en-text:checked + .profile__description {
  display: block;
}

label {
  font-size: 0.75rem;
}

.notfound-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.notfound__wrapper {
  padding-top: 3.75rem;
}
.notfound__wrapper h2 {
  margin-bottom: 1.25rem;
}
.notfound__text {
  margin-bottom: 0;
  margin-right: 1.25rem;
  letter-spacing: 0.1em;
  line-height: 2.08;
}
@media print, screen and (max-width: 39.99875em) {
  .notfound__text {
    margin-top: 1.25rem;
  }
}
.notfound__text h2 {
  margin-top: -0.375rem;
  margin-bottom: 0.375rem;
  font-size: 0.8125rem;
  font-weight: 600;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-contents {
  flex-grow: 1;
}

.page-content.contact .page-content__inner {
  width: 100%;
}
.page-content.contact .wp-block-group__inner-container {
  display: flex;
}
@media print, screen and (max-width: 63.99875em) {
  .page-content.contact .wp-block-group__inner-container {
    flex-direction: column;
  }
}
.page-content.contact p {
  flex: 0 0 auto;
  width: calc(8.3333333333% * 3.65 );
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 2.08;
}
@media print, screen and (max-width: 39.99875em) {
  .page-content.contact p {
    width: 100%;
  }
}
.page-content.contact .mw_wp_form {
  width: calc(8.3333333333% * 9);
  padding-left: 8.3333333333%;
  text-align: left;
}
@media print, screen and (max-width: 63.99875em) {
  .page-content.contact .mw_wp_form {
    width: 100%;
    padding-left: 0;
  }
}
.page-content.contact .mw_wp_form table {
  width: 100%;
  margin: 0;
}
.page-content.contact .mw_wp_form tr {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto;
  margin-bottom: 0.75rem;
}
.page-content.contact .mw_wp_form tr:last-of-type {
  margin-bottom: 2rem;
}
.page-content.contact .mw_wp_form th {
  display: block;
  text-align: left;
  min-height: 0%;
  margin-top: -0.34375rem;
  padding-bottom: 0.375rem;
  font-size: 0.75rem;
}
.page-content.contact .mw_wp_form td {
  display: block;
}
.page-content.contact .mw_wp_form input {
  width: 52%;
  padding-top: 0.75rem;
  padding-bottom: 0.375rem;
  border: none;
  border-bottom: 1px solid #595959;
  font-size: 0.875rem;
  letter-spacing: 0.075em;
  color: #595959;
}
@media print, screen and (max-width: 63.99875em) {
  .page-content.contact .mw_wp_form input {
    width: 80%;
  }
}
.page-content.contact .mw_wp_form input[name=お名前], .page-content.contact .mw_wp_form input[name=ふりがな] {
  width: 37%;
}
@media print, screen and (max-width: 39.99875em) {
  .page-content.contact .mw_wp_form input[name=お名前], .page-content.contact .mw_wp_form input[name=ふりがな] {
    width: 55%;
  }
}
.page-content.contact .mw_wp_form p {
  width: 100%;
}
.page-content.contact .mw_wp_form textarea {
  width: 100%;
  margin-top: 0.375rem;
  padding: 0;
  border: none;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #595959;
  font-size: 0.875rem;
  letter-spacing: 0.075em;
  line-height: 2.5em;
  color: #595959;
  background: linear-gradient(#cfd3de 1px, transparent 1px) #fff;
  background-size: auto 2.5em;
  background-origin: border-box;
  background-attachment: local;
}

.mw_wp_form_preview {
  letter-spacing: 0.1em;
  line-height: 2.08;
}
.mw_wp_form_preview td {
  font-size: 0.875rem;
}

.mwform__btn {
  display: flex;
  justify-content: flex-end;
}

.form-confirm-button,
.form-back-button,
.form-submit-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.625rem;
  padding: 0.6875rem 1.3125rem;
  border: 1px solid #707070;
  border-radius: 3px;
  color: #595959;
  background-color: #fff;
  cursor: pointer;
}
.form-confirm-button::after,
.form-back-button::after,
.form-submit-button::after {
  position: absolute;
  content: "";
  z-index: -1;
  width: calc(100% - 12px);
  height: 100%;
  left: 6px;
  bottom: -3px;
  border-radius: 3px;
  background-color: #cfd3de;
}
.form-confirm-button:hover,
.form-back-button:hover,
.form-submit-button:hover {
  transform: translateY(3px);
  z-index: 2;
}
.form-confirm-button:hover::after,
.form-back-button:hover::after,
.form-submit-button:hover::after {
  content: "";
  width: 0;
  height: 0;
}

.archive,
.notfound__wrapper {
  margin-left: 2.5rem;
}
@media print, screen and (max-width: 63.99875em) {
  .archive,
.notfound__wrapper {
    margin-left: 1.25rem;
  }
}
.archive__header,
.notfound__wrapper__header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.25rem;
  margin-right: 1.25rem;
}
@media print, screen and (max-width: 39.99875em) {
  .archive__header,
.notfound__wrapper__header {
    flex-direction: column;
    align-items: flex-start;
  }
  .archive__header h2,
.notfound__wrapper__header h2 {
    margin-bottom: 0.625rem;
  }
  .archive__header .archive__lists,
.notfound__wrapper__header .archive__lists {
    flex-wrap: wrap;
    margin: 0;
  }
}
.archive__title,
.notfound__wrapper__title {
  min-width: 15rem;
  margin-bottom: 0;
  font-size: 0.875rem;
}
@media print, screen and (max-width: 39.99875em) {
  .archive__title,
.notfound__wrapper__title {
    min-width: inherit;
    flex: 1 1 auto;
  }
}
.archive__lists,
.notfound__wrapper__lists {
  display: flex;
  margin: 0;
  margin-left: 0.1875rem;
  padding: 0;
  font-size: 0.6875rem;
}
.archive__lists li::before,
.notfound__wrapper__lists li::before {
  content: "-";
  padding: 0 0.22rem;
}
.archive__lists li:first-of-type::before,
.notfound__wrapper__lists li:first-of-type::before {
  content: none;
}
.archive__lists a,
.notfound__wrapper__lists a {
  text-decoration: underline;
}
.archive p,
.notfound__wrapper p {
  font-size: 0.6875rem;
  margin-bottom: 0;
}

.current-cat a {
  color: #777;
  text-decoration: none;
}

.thumb-post {
  position: relative;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  height: 6.25rem;
  margin-bottom: 1.25rem;
}
.thumb-post__titles {
  align-self: flex-end;
  margin-bottom: 0;
  min-width: 15rem;
}
@media print, screen and (max-width: 39.99875em) {
  .thumb-post__titles {
    position: absolute;
    clip: rect(0 0 0 0);
  }
}
.thumb-post__title-ja {
  font-size: 0.75rem;
  margin-bottom: 0.5625rem;
}
.thumb-post__title-en {
  font-size: 0.6875rem;
  color: #777;
}
.thumb-post__images img {
  width: auto;
  height: 6.25rem;
  max-height: 100%;
  max-width: inherit;
  margin-right: 1px;
  vertical-align: bottom !important;
}
.thumb-post__more::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.page-content {
  font-size: 0.75rem;
  letter-spacing: 0.1em;
}
.page-content__inner {
  width: calc(8.3333333333% * 9 );
  padding-bottom: 6.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media print, screen and (max-width: 63.99875em) {
  .page-content__inner {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
.page-content__title {
  margin-top: 0;
  margin-bottom: 1.25rem;
  font-size: 1.625rem;
}
@media print, screen and (max-width: 39.99875em) {
  .page-content__title {
    font-size: 0.9375rem;
  }
}
.page-content h2 {
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
}
@media print, screen and (max-width: 39.99875em) {
  .page-content h2 {
    font-size: 0.875rem;
  }
}
.page-content h3 {
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  font-size: 0.8125rem;
  font-weight: 600;
}
.page-content p {
  margin-bottom: 0.75rem;
  letter-spacing: 0;
  line-height: 1.75;
}
.page-content ol {
  margin-top: -0.34375rem;
  line-height: 2.08;
}

.wp-block-separator {
  margin: 2rem auto;
}

.single .site-contents, .page .site-contents {
  margin-left: calc(1.25rem * 2 - 1rem / 2);
}
@media print, screen and (max-width: 63.99875em) {
  .single .site-contents, .page .site-contents {
    margin-left: 0;
  }
}

.single-page__inner {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media print, screen and (max-width: 63.99875em) {
  .single-page__inner {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .single-page__header {
    display: flex;
    clip: auto;
    overflow: auto;
    position: static;
    padding: 1rem 0;
    font-size: 0.875rem;
  }
}
.single-page__header-title {
  margin: 0;
  margin-top: -0.1875rem;
  width: 50%;
  font-size: 0.875rem;
}
.single-page__header-time {
  width: 50%;
  font-size: 0.6875rem;
}
.single-page__bar {
  transition: height 300ms cubic-bezier(0.19, 1, 0.22, 1), transform 250ms cubic-bezier(0.19, 1, 0.22, 1);
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}
body.single.site-footer-in .single-page__bar {
  transform: translateY(100%);
}
.single-page__bar-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding: 0;
  border: none;
  text-align: left;
  background-color: #fff;
  color: #595959;
  cursor: pointer;
}
@media print, screen and (min-width: 40em) {
  .single-page__bar-label {
    display: none;
  }
}
.text-open .single-page__bar-label {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.single-page__bar-label .right-arrow {
  padding-right: 3.625rem;
}
.single-page__bar-label .left-arrow {
  padding-left: 3.625rem;
}
.single-page__bar-title {
  padding: 1rem 1.25rem;
  font-size: 0.75rem;
}
.text-open .single-page__bar-title {
  padding-left: 1.8125rem;
}

.work-text {
  display: inline-block;
  float: left;
  width: calc(8.3333333333% * 4 );
  padding-bottom: 6.25rem;
  font-size: 0.75rem;
}
.work-text__inner p {
  margin-top: -0.34375rem;
  margin-bottom: 2em;
  letter-spacing: 0.1em;
  line-height: 2.08;
}
.work-text__title {
  margin-top: 0;
  margin-bottom: 0.625rem;
  font-size: 1.625rem;
}
@media print, screen and (max-width: 39.99875em) {
  .work-text__title {
    display: none;
  }
}
.work-text__title-en {
  margin-top: 0;
  margin-bottom: 1.25rem;
  font-size: 1rem;
}
@media print, screen and (max-width: 39.99875em) {
  .work-text__title-en {
    display: none;
  }
}
.work-text__title-sub {
  display: none;
}
@media print, screen and (max-width: 39.99875em) {
  .work-text__title-sub {
    display: block;
    margin-top: 2.875rem;
    margin-bottom: 0.3125rem;
    font-size: 0.9375rem;
  }
}
.work-text__title-en-sub {
  display: none;
}
@media print, screen and (max-width: 39.99875em) {
  .work-text__title-en-sub {
    display: block;
    margin-top: 0;
    margin-bottom: 1.25rem;
    font-size: 0.75rem;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .work-text {
    transition: all 250ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateX(110%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-left: 1.25rem;
  }
  .text-open .work-text {
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateX(0);
    display: block;
    overflow-y: auto;
    background-color: #fff;
  }
  .work-text__inner {
    width: 74%;
  }
}
.work-text__outline h2 {
  font-size: 0.8125rem;
}
.work-text__outline p {
  font-size: 0.6875rem;
}
@media print, screen and (max-width: 39.99875em) {
  .work-text__outline {
    margin-top: 2em;
  }
}
@media print, screen and (min-width: 40em) {
  .work-text__open {
    display: none;
  }
}
.work-text__open header {
  margin-bottom: 3.75rem;
}
.work-text__open .header__cover {
  margin-left: 0;
}
.work-text__open .header__cover p {
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.work-text__open .header__cover p:first-of-type {
  position: relative;
  height: 0;
  margin-top: 0;
  padding-top: 9%;
}
.work-text__open .header__cover p:first-of-type img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.work-text__open .single-page__header {
  margin-top: -0.1875rem;
  margin-right: 1.25rem;
}

.joint__title {
  padding: 0.375rem;
}
.joint__lists {
  padding: 0;
  line-height: 2.08;
}

.work-photo {
  transition: all 250ms cubic-bezier(0.19, 1, 0.22, 1);
  width: calc(8.3333333333% * 9 - 1rem);
  padding-left: calc(8.3333333333% * 5 );
}
@media print, screen and (max-width: 39.99875em) {
  .work-photo {
    padding: 0;
  }
  .text-open .work-photo {
    transform: translateX(-150%);
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.pageNav {
  clear: both;
  margin-top: 11.25rem;
}
@media print, screen and (max-width: 39.99875em) {
  .pageNav {
    margin-top: 3.75rem;
  }
}
.pageNav__item {
  overflow: hidden;
  margin-top: 2.8125rem;
}
@media print, screen and (max-width: 39.99875em) {
  .pageNav__item {
    margin-top: 1.25rem;
  }
}
.pageNav__link {
  display: flex;
  max-height: 6.1875rem;
}
@media print, screen and (max-width: 39.99875em) {
  .pageNav__link {
    max-height: none;
    flex-direction: column;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .pageNav__images {
    max-height: 6.1875rem;
  }
}
.pageNav__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 8.6875rem;
}
@media print, screen and (max-width: 39.99875em) {
  .pageNav__text {
    flex-direction: row;
    justify-content: flex-start;
    margin: 0.5625rem 0;
  }
}
.pageNav__text p {
  margin: 0;
}
.pageNav__text span {
  font-size: 0.75rem;
}
@media print, screen and (max-width: 39.99875em) {
  .pageNav__text span {
    margin: 0 1em;
  }
  .pageNav__text span br {
    display: none;
  }
}

.pageNav__images {
  display: flex;
  flex-wrap: nowrap;
}
.pageNav__images img {
  flex-shrink: 0;
}

.prev {
  margin-left: 0.75rem;
}

.next {
  margin-right: 0.75rem;
}
.next .pageNav__images {
  flex-direction: row-reverse;
}
.next .pageNav__link {
  justify-content: flex-end;
}
.next .pageNav__text {
  padding-left: 0.75rem;
}
@media print, screen and (max-width: 39.99875em) {
  .next .pageNav__text {
    flex-direction: row-reverse;
  }
}