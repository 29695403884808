.single, .page{
  & .site-contents{
    margin-left: calc(#{$graph} * 2 - #{$gutter} / 2);
    @include breakpoint(medium down){
      margin-left: 0;
    };
  }
}
.single-page{
  @extend .wideLimit;
  &__inner{
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
    @include breakpoint(medium down){
      padding-left: $graph;
      padding-right: $graph;
    }
  }
  &__header{
    @extend .visually-hidden;
    @include breakpoint(small down){
      display: flex;
      clip: auto;
      overflow: auto;
      position: static;
      padding: 1rem 0;
      font-size: rem-calc(14);
    }
  }
  &__header-title{
    @extend %ja-font-light;
    margin: 0;
    margin-top: rem-calc(-3);
    width: 50%;
    font-size: rem-calc(14);
  }
  &__header-time{
    width: 50%;
    font-size: rem-calc(11);
  }
  &__bar{
    transition:
      height 300ms ease(out-expo),
      transform 250ms ease(out-expo);
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0; right: 0;
    background-color: $white;
    body.single.site-footer-in &{
      transform: translateY(100%);
    }
  }
  &__bar-label{
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    width: 100%;
    padding: 0;
    border: none;
    text-align: left;
    background-color: $white;
    color: $dark-brown;
    cursor: pointer;
    @include breakpoint(medium up){
      display: none;
    }
    .text-open &{
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    & .right-arrow{
      padding-right: $graph * 2.9;
    }
    & .left-arrow{
      padding-left: $graph * 2.9;
    }
  }
  &__bar-title{
    padding: rem-calc(16) rem-calc(20);
    font-size: rem-calc(12);
    .text-open &{
      padding-left: rem-calc(29);
    }
  }
} //single-page close


.work-text{
  display: inline-block;
  float: left;
  width: calc(#{$column} * 4 );
  padding-bottom: rem-calc(100);
  font-size: rem-calc(12);
  &__inner p{
    margin-top: rem-calc(-11/2);
    margin-bottom: 2em;
    letter-spacing: 0.1em;
    line-height: 2.08;
  }
  &__title{
    margin-top: 0;
    margin-bottom: $graph /2;
    font-size: rem-calc(26);
    @include breakpoint(small down){
      display: none;
    };
  }
  &__title-en{
    margin-top: 0;
    margin-bottom: $graph;
    font-size: rem-calc(16);
    @include breakpoint(small down){
      display: none;
    };
  }
  &__title-sub{
    display: none;
    @include breakpoint(small down){
      display: block;
      margin-top: rem-calc(46);
      margin-bottom: rem-calc(5);
      font-size: rem-calc(15);
    };
  }
  &__title-en-sub{
    display: none;
    @include breakpoint(small down){
      display: block;
      margin-top: rem-calc(0);
      margin-bottom:  rem-calc(20);
      font-size: rem-calc(12);
    };
  }
  @include breakpoint(small down){
    transition: all 250ms ease(out-expo);
    transform: translateX(110%);
    position: fixed;
    top: 0; left: 0;
    width: 100vw;
    height: 100vh;
    padding-left: $graph;
    .text-open &{
      transition: all 300ms ease(out-expo);
      transform: translateX(0);
      display: block;
      overflow-y: auto;
      background-color: $white;
    }
    &__inner{
      width: 74%;
    }
  };
  &__outline{
    h2{ font-size: rem-calc(13); }
    p{
      font-size: rem-calc(11);
    }
    @include breakpoint(small down){
      margin-top: 2em;
    };
  }
  &__open{
    @include breakpoint(medium up){
      display: none;
    };
    header{
      margin-bottom: $graph * 3;
    }
    & .header__cover{
      margin-left: 0;
      p{
        margin-top: rem-calc(8);
        margin-bottom: 0;
      }
      p:first-of-type{
        position: relative;
        height: 0;
        margin-top: 0;
        padding-top: 9%;
        img{
          position: absolute;
          top: 0; left: 0;
          width: 100%; height: 100%;
        }
      }
    }
    & .single-page__header{
      margin-top: rem-calc(-3);
      margin-right:  $graph;
    }
  }
}
.joint{
  &__title{
    padding: rem-calc(6);
  }
  &__lists{
    padding: 0;
    line-height: 2.08;
  }
}

.work-photo{
  transition: all 250ms ease(out-expo);
  width: calc(#{$column} * 9 - #{$gutter});
  padding-left: calc(#{$column} * 5 );
  @include breakpoint(small down){
    padding: 0;
    .text-open &{
      transform: translateX(-150%);
      transition: all 300ms ease(out-expo);
    }
  };
}

.pageNav{
  @extend .wideLimit;
  clear: both;
  margin-top: rem-calc(180);
  @include breakpoint(small down){
    margin-top: rem-calc(60);
  }
  &__item{
    overflow: hidden;
    margin-top: $graph * 2.25;
    @include breakpoint(small down){
      margin-top: $graph;
    }
  }
  &__link{
    display: flex;
    max-height: rem-calc(99);
    @include breakpoint(small down){
      max-height: none;
      flex-direction: column;
    }
  }
  &__images{
    @include breakpoint(small down){
      max-height: rem-calc(99);
    }
  }
  &__text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: rem-calc(139);
    @include breakpoint(small down){
      flex-direction: row;
      justify-content: flex-start;
      margin: rem-calc(9) 0;
    };
    p{
      margin: 0;
    }
    span{
      font-size: rem-calc(12);
      @include breakpoint(small down){
        margin: 0 1em;
        br{ display: none; }
      };
    }
  }
}

.pageNav__images{
  display: flex;
  flex-wrap: nowrap;
  img{
    flex-shrink: 0;
  }
}
.prev{
  margin-left: rem-calc(12);
}
.next{
  margin-right: rem-calc(12);
  .pageNav__images{
    flex-direction: row-reverse;
  }
  & .pageNav__link{
    justify-content: flex-end;
  }
  & .pageNav__text{
    padding-left: rem-calc(12);
    @include breakpoint(small down){
      flex-direction: row-reverse;
    };
  }
}
