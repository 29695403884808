.right-arrow,
.left-arrow{
  position: relative;
  display: inline;
  &::before,
  &::after{
  content: "";
  position: absolute;
  bottom: calc(1em /4);
  height: 1px;
  background: $dark-brown;
  }
  &::before{
    width: 45px;
  }
  &::after{
    width: 12px;
    overflow: hidden;
  }
  &__link{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
    }
  }
}

.right-arrow{
  padding-right: $graph * 4.9;
  &::before,
  &::after{
    right: 0;
  }
  &::after{
    transform-origin: right center;
    transform: rotate(33deg);
  }
}

.left-arrow{
  padding-left: $graph *  4.9;
  &::before,
  &::after{
    left: 0;
  }
  &::after{
    transform-origin: left center;
    transform: rotate(-33deg);
  }
}

.x-mark{
  position: relative;
  &::before,
  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 24px;
    height: 1px;
    background: $light-gray;
  }
  &::before{
    transform: rotate(33deg);
  }
  &::after{
    transform: rotate(-33deg);
  }
}

.page-contents,
.page-content{
  padding-top: $graph * 3;
}
