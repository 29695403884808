.site-header:before{
  content: '';
  transition: background-color 0.025s linear;
  // pointer-events: none;
  position: fixed; z-index: 128;
  background: rgba($white, 0);
  body.menu-open &{
    top: 0; bottom: 0;
    left: 0; right: 0;
    background: rgba($black, 0.05);
  }
}

.header{
  padding-bottom: $graph;
  &__cover{
    display: flex;
    justify-content: space-between;
    margin-top: $graph * 1.5;
    margin-left: $graph * 2;
    @include breakpoint(medium down){
      margin-left: $graph;
    };
  }
  &__title{
    a{
      display: flex;
      align-items: flex-end;
      font-size: rem-calc(11);
      @include breakpoint(small down){
        display: block;
      };
    }
    h1{
      margin: 0;
      margin-block-start: 0;
      @include breakpoint(small down){
        position: relative;
        height: 0;
        padding-top: 9%;
        img{
          position: absolute;
          top: 0; left: 0;
          width: 100%; height: 100%;
        }
      };
    }
    p{
      font-size: rem-calc(11);
      margin-top: rem-calc(8);
      margin-bottom: rem-calc(-11/4);
      @include breakpoint(medium up){
        margin-left: rem-calc(23);
      };
    }
  }
}

.global-nav{
  position: fixed;
  top: 0; bottom: 0;
  right: 0;
  z-index: 200;
  pointer-events: none;
  .menu-open &{
    display: flex;
    flex-direction: column;
    height: auto;
    pointer-events: all;
  }
  &__menu{
    transition: all 200ms ease(out-expo);
    transform: translateX(50%);
    pointer-events: all;
    background-color: $white;
    .menu-open &{
      transition: all 250ms ease(out-expo);
      transform: translateX(0%);
    }
  }
  &__menu-btn,
  &__close-btn{
    position: relative;
    margin: 0;
    padding: 33px 47.5px;
    border: none;
    background-color: $white;
    cursor: pointer;
  }
  &__menu-btn{
    transition: all 200ms ease(out-expo);
    .menu-open &{
      transition: all 250ms ease(out-expo);
      transform: translateX(-95px);
    }
  &__close-btn{
    transition: all 200ms ease(out-expo);
    .menu-open &{
      transition: all 250ms ease(out-expo);
    }
  }
  }
  &__menu-label,
  &__menu-label::before,
  &__menu-label::after,
  &__close-label,
  &__close-label::before{
    content: '';
    position: absolute;
    display: block;
    height: 2px;/*線の太さ*/
    width: 53px;/*長さ*/
    background: $dark-brown;
  }
  &__menu-label,
  &__close-label{
    top: 50%; left: 50%;
    transform: translateX(-50%);
  }
  &__menu-label::before {
    bottom: 9px;
  }
  &__menu-label::after {
    bottom: -9px;
  }
  &__close-label,
  &__close-label::before{
    width: 28px;
  }
  &__close-label{
    transform:translateX(-50%) rotate(45deg);
  }
  &__close-label::before {
    transform: rotate(-90deg);
  }
  &__lists{
    transition: all 200ms ease(out-expo);
    transform: translateX(100%);
    flex-grow: 1;
    overflow-y: scroll;
    width: 100%;
    margin: 0;
    padding: rem-calc(36) 0 rem-calc(36) rem-calc(12);
    background-color: $white;
    font-size: rem-calc(12);
    .menu-open &{
      transition: all 250ms ease(out-expo);
      transform: translateX(0%);
    }
  }
  &__list{
    padding-bottom: $graph * 1.6;
    position: relative;
    & a::after{
      content: "";
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
    }
  }
  &__title{
    margin-bottom: 0.75em;
  }
  &__title-en{
    transition: color 0.5s ease(out-back);
    max-width: 15em;
    font-size: rem-calc(11);
    color: $aa-gray;
    a:hover &{
      color: $light-gray;
    }
  }
}
