.about{
  position: relative;
}
.about-header{
  position: absolute;
  display: inline-block;
  top: rem-calc(-11/2); right: 0;
}



.about-content{
  display: flex;
  line-height: 1.75;
  @include breakpoint(small down){
    flex-direction: column;
    margin-right: $graph;
  };
  &__title{
    margin: rem-calc(-11/3) 0 rem-calc(14);
    font-size: rem-calc(13);
  }
  &__office{
    display: flex;
    flex-direction: column;
    font-size: rem-calc(12);
    @include breakpoint(medium up){
      width: 20%;
      min-width: 20rem;
      margin-top: rem-calc(-11/3);
    };
    @include breakpoint(small down){
      margin-top: 2rem;
    };
  }
  &__profile{
    display: flex;
    flex-direction: column;
    padding-left: rem-calc(40);
    .profile-container__title-en{
      margin: rem-calc(-11/2) 0 rem-calc(14);
    }
    @include breakpoint(small down){
      margin-top: 3rem;
      padding-left: 0;
    };
  }
  a{
    @extend %content-link;
  }
}

.profile-container{
  @include breakpoint(medium down){
    flex-direction: column;
  };
  &__cover{
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
}

.profile{
  padding-right: rem-calc(48);
  @include breakpoint(medium down){
    padding-bottom: rem-calc(16);
    padding-right: rem-calc(20);
    &:last-child{
      padding-bottom: 0;
    }
  };
}
.about-footer{
  display: flex;
  padding-top: rem-calc(50);
  a{ @extend %content-link; }
  @include breakpoint(small down){
    display: block;
  };
}
.copyright{
  font-size: rem-calc(12);
  line-height: 1.75;
}

.site-footer-nav{
  flex-grow: 1;
  align-self: flex-end;
  padding-right: $graph * 2.6;
  text-align: right;
  font-size: rem-calc(11);
  white-space: nowrap;
  @include breakpoint(small down){
    margin-top: rem-calc(16);
    padding-right: 0;
    text-align: left;
  };
}
