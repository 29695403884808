.alignfull{
  margin-left: -128.5%;
  width: 309.09%;
  @include breakpoint(medium down){
    margin-left: calc(26% - 50vw);
    margin-right: calc(74% - 50vw);
    width: 100vw;
  };
  @include breakpoint(small down){
    margin-left: calc(70% - 50vw);
    margin-right: calc(30% - 50vw);
  };
}
.alignwide{
  margin-right: calc( -100% / 13 * 4.5);
  width: calc( 100% + 100% / 2);
  @include breakpoint(small down){
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  };
}

.wp-block-columns{
  &.alignfull{ @extend .alignfull; }
  &.alignwide{ @extend .alignwide; }
}
