
%link{
  transition: color 0.5s ease(out-back);
  color: $black;
  cursor: pointer;
  &:hover{ color: $aa-gray; }
}
%content-link{
  @extend %link;
  text-decoration: underline;
  cursor: pointer;
}

a{
  @extend %link;
}
