$container-max-width: rem-calc(1088) + 1rem;
$column: calc(100% / 12);
$gutter: 1rem;
$grid: calc(#{$column} - #{$gutter});
$graph: rem-calc(20);

.wideLimit{
  max-width:  $container-max-width;
  margin:auto;
}
