.page-content{
  @extend .wideLimit;
  font-size: rem-calc(12);
  letter-spacing: 0.1em;
  &__inner{
    width: calc(#{$column} * 9 );
    padding-bottom: 6.25rem;
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
    @include breakpoint(medium down){
      padding-left: $graph;
      padding-right: $graph;
    };
  }
  &__title{
    margin-top: 0;
    margin-bottom: $graph;
    font-size: rem-calc(26);
    @include breakpoint(small down){
      font-size: rem-calc(15);
    }
  }
  & h2{
    margin-top: rem-calc(24);
    font-size: rem-calc(16);
    font-weight: 600;
    @include breakpoint(small down){
      font-size: rem-calc(14);
    }
  }
  & h3{
    margin-top: rem-calc(16);
    margin-bottom: rem-calc(12);
    font-size: rem-calc(13);
    font-weight: 600;
  }
  & p{
    margin-bottom: rem-calc(12);
    letter-spacing: 0;
    line-height: 1.75;
  }
  & ol{
    margin-top: rem-calc(-11/2);
    line-height: 2.08;
  }
}

.wp-block-separator{
  margin: rem-calc(32) auto;
}
