.notfound-page{
  display: flex;
 	flex-direction: column;
 	justify-content: space-between;
 	min-height: 100vh;
}

.notfound{
  @extend .wideLimit;
  &__wrapper{
    padding-top: $graph * 3;
    & h2{
      @extend .wideLimit;
      margin-bottom: rem-calc(20);
    }
  }
  &__text{
    margin-bottom: 0;
    margin-right: rem-calc(20);
    letter-spacing: 0.1em;
    line-height: 2.08;
    @include breakpoint(small down){
      margin-top: rem-calc(20);
    };
    & h2{
      margin-top: rem-calc(-12/2);
      margin-bottom: rem-calc(6);
      font-size: rem-calc(13);
      font-weight: 600;
    }
  }
}
