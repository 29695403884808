.site-footer{
  margin-left: $graph * 2;
  padding-top: rem-calc(109);
  padding-bottom: rem-calc(45);
  @include breakpoint(small down){
    margin-left: $graph;
  };
  h3,h4,h5,h6{
    margin: 0;
    font-size: rem-calc(12);
  }
  p{
    margin: 0;
    line-height: 1.75;
  }
  &__contact{
    font-size: rem-calc(14);
    @include breakpoint(small down){
      margin-bottom: $graph;
      & span{ z-index: -1; }
    };
  }
}
