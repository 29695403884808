
body{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-contents{
  flex-grow: 1;
}
