.archive,
.notfound__wrapper{
  margin-left: $graph * 2;
  @include breakpoint(medium down){
    margin-left: $graph;
  };
  &__header{
    display: flex;
    align-items: flex-end;
    margin-bottom: $graph;
    margin-right: $graph;
    @include breakpoint(small down){
      flex-direction: column;
      align-items: flex-start;
      & h2{
        margin-bottom: rem-calc(10);
      }
      & .archive__lists{
        flex-wrap: wrap;
        margin: 0;
      }
    };
  }
  &__title{
    min-width: $graph * 12;
    margin-bottom: 0;
    font-size: rem-calc(14);
    @include breakpoint(small down){
      min-width: inherit;
      flex: 1 1 auto;
    };
  }
  &__lists{
    display: flex;
    margin: 0;
    margin-left: rem-calc(3);
    padding:0;
    font-size: rem-calc(11);
    & li::before{
      content: "-";
      padding: 0 0.22rem;
    }
    & li:first-of-type::before{
      content: none;
    }
    & a{
      text-decoration: underline;
    }
  }
  & p{
    font-size: rem-calc(11);
    margin-bottom: 0;
  }
}

.current-cat a{
  color: $aa-gray;
  text-decoration: none;
}

.thumb-post{
  position: relative;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  height: $graph * 5;
  margin-bottom: $graph;
  &__titles{
    align-self: flex-end;
    margin-bottom: 0;
    min-width: $graph * 12;
    @include breakpoint(small down){
      position: absolute;
      clip: rect(0 0 0 0);
    };
  }
  &__title-ja{
    font-size: rem-calc(12);
    margin-bottom: rem-calc(9);
  }
  &__title-en{
    font-size: rem-calc(11);
    color: $aa-gray;
  }
  &__images{
    // tiny slider にまかせる
    // display: flex;
    // flex: 1 1 auto;
    & img{
      width: auto;
      height: $graph * 5;
      max-height: 100%;
      max-width: inherit;
      margin-right: 1px;
      vertical-align: bottom !important;
    }
  }
  &__more::after{
    content: '';
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
  }
}
