.wp-block-column{
  h2:first-of-type{
    margin-top:0;
  }
  img{
    margin-top: calc(1rem / 3);
  }
  figcaption{
    font-size: rem-calc(16);
    font-weight: bold;
    letter-spacing: (23em / 1000);
    line-height: rem-calc(24);
  }
}
.wp-block-image{
  figcaption{
    margin-top: rem-calc(16);
    font-weight: bold;
  }
}
